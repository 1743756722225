<script lang="ts">
	import type { GetToolsCoreByOutputQuery } from "src/graphql-operations";
	import { onMount } from "svelte";
	export let color: string,
		hoverable: boolean,
		selected: boolean,
		href: string,
		titled = true,
		index: number;
	export let outputTool: GetToolsCoreByOutputQuery["outputs_tools"][0];

	let toolsIntroSpan: HTMLSpanElement;
	let cardDiv: HTMLDivElement;
	let limit = 160;

	const sectionsColors = {
		think: "text-think-500",
		experience: "text-experience-500",
		manage: "text-manage-500",
	};

	$: if (cardDiv) {
		limit = cardDiv.offsetHeight * 0.7 ?? 0;
	}

	onMount(() => {
		if (toolsIntroSpan?.innerText) {
			const aboveLimit = toolsIntroSpan.innerText.length > limit;
			let text = "";
			const dotsOrEmpty = aboveLimit ? "..." : "";
			toolsIntroSpan.innerText.split("").forEach((letter, index) => {
				if (index <= limit) {
					text += letter;
				}
			});
			toolsIntroSpan.innerText = text + dotsOrEmpty;
		}
	});

	const aasaasadas = (asdada: number) => {
		if (asdada >= 10) return asdada;
		return "0" + asdada;
	};
</script>

<div
	bind:this={cardDiv}
	class="aspect-h-1 aspect-w-1 relative rounded-[15px] bg-white shadow-lg transition-all"
	class:bg-gray-300={href === "#"}
	class:cursor-not-allowed={href === "#"}
>
	<a
		class="tool-card group h-full w-full rounded-[15px] shadow-lg transition-all"
		class:hover:bg-experience-500={!hoverable && color === "experience"}
		class:hover:bg-manage-500={!hoverable && color === "manage"}
		class:hover:bg-think-500={!hoverable && color === "think"}
		class:pointer-events-none={href === "#"}
		{href}
	>
		<div class="flex h-full flex-1 flex-col rounded-[15px] shadow-inner">
			<div
				class="relative w-full group-hover:mix-blend-plus-lighter group-hover:invert {titled
					? 'h-4/5'
					: 'h-full'}"
			>
				{#if selected}
					<span class="absolute right-4 top-4 {sectionsColors[color]}">
						<svg
							fill="none"
							height="30"
							viewBox="0 0 30 30"
							width="30"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10.944 17.8256L9.47891 16.3597L9.83465 16.0039L11.2994 17.4695L12.3601 18.5308L13.421 17.4698L20.1489 10.7419L20.5208 11.1139L13.7946 17.8414L13.7942 17.8418L12.3768 19.2584L10.9443 17.8259L10.944 17.8256ZM15 1.5C7.54415 1.5 1.5 7.54415 1.5 15C1.5 22.4558 7.54415 28.5 15 28.5C22.4558 28.5 28.5 22.4558 28.5 15C28.5 7.54415 22.4558 1.5 15 1.5Z"
								fill="currentColor"
								stroke="white"
								stroke-width="3"
							/>
						</svg>
					</span>
				{/if}

				<!-- <img
					class="h-[105%] w-full"
					alt={outputTool.tool.name}
					src="/img{outputTool.tool.icons.find((icon) => icon.type === 'big')
						.file.path}"
				/> -->

				<div class="flex h-full w-full items-center justify-center">
					<h1 class="text-5xl {sectionsColors[color]}">AULA {aasaasadas(index + 1)}</h1>
				</div>
			</div>

			<div
				class="flex h-1/5 w-full flex-col justify-end px-4 pb-2 transition-all group-hover:text-white"
				class:text-experience-500={color === "experience"}
				class:text-manage-500={color === "manage"}
				class:text-think-500={color === "think"}
			>
				{#if titled}
					{#if outputTool.tool.name.includes(":")}
						<span class="text-left text-xs">
							{outputTool.tool.name.split(":")[0]}
						</span>
						<span class="text-left text-xs font-semibold md:text-lg">
							{outputTool.tool.name.split(":")[1]}
						</span>
					{:else}
						<span class="text-left text-xs font-semibold md:text-lg">
							{outputTool.tool.name}
						</span>
					{/if}
				{/if}
			</div>
		</div>

		{#if hoverable}
			<div
				class="absolute left-0 top-0 flex h-full w-full
				       flex-col rounded-xl opacity-0 shadow-inner transition-all group-hover:opacity-100"
				class:bg-experience-100={color === "experience"}
				class:bg-manage-100={color === "manage"}
				class:bg-think-100={color === "think"}
			>
				<div
					style="color: var(--color-{color}-500);"
					class="flex w-full flex-col px-4 py-2"
					class:text-experience-500={color === "experience"}
					class:text-manage-500={color === "manage"}
					class:text-think-500={color === "think"}
				>
					{#if titled}
						{#if outputTool.tool.name.includes(":")}
							<span class="text-left text-xs">
								{outputTool.tool.name.split(":")[0]}
							</span>
							<span class="text-left text-xs font-semibold md:text-lg">
								{outputTool.tool.name.split(":")[1]}
							</span>
						{:else}
							<span class="text-left text-xs font-semibold md:text-lg">
								{outputTool.tool.name}
							</span>
						{/if}
					{/if}
				</div>
				<div
					class="line-clamp-8 m-auto w-full text-ellipsis px-4 text-sm text-neutral-700 md:text-base"
				>
					<span bind:this={toolsIntroSpan}>
						{outputTool.tool.intro}
					</span>
				</div>
			</div>
		{/if}
	</a>
</div>

<style>
	.tool-card {
		/* Vidro */
		box-shadow:
			5px 5px 18px rgba(0, 0, 0, 0.1),
			inset 2px 6px 27px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(54px);
	}

	.tool-card:hover {
		box-shadow:
			5px 5px 18px rgba(0, 0, 0, 0.1),
			inset 2px 6px 27px rgba(255, 255, 255, 0.1);
	}
</style>
